import * as React from 'react';
import { ReactComponent as AOLogo } from './andopen-logo.svg';
import { ReactComponent as AOMark } from './andopen-mark.svg';

export default function AndOpenLogo({ style, color, version }){
  return (
    <React.Fragment>
      { version === 'mark'
        ? <AOMark
            fill={color}
            className="andopen-logo"
            style={style}
          />
        : <AOLogo
            fill={color}
            className="andopen-logo"
            style={style}
          />
      }
    </React.Fragment>
  )
}


