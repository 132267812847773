import * as React from 'react';

import './App.css';
import { Routes, Route } from "react-router-dom";

// Pages
import Home from './pages/Home.js';

import { createTheme } from '@mui/material/styles';
import themeSettings from './theme.js'
const theme = createTheme(themeSettings);

const App = () => {
  console.log(`&Open Demo. \nintercom-messenger-test-harness v${process.env.REACT_APP_VERSION}@${process.env.REACT_APP_GIT}`);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home color={theme.palette.primary.light} />} />
      </Routes>
    </div>
  );
}

export default App;
